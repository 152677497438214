export enum AvalancheAspect {
    Southwest = 1,
    Southeast = 2,
    South = 3,
    West = 4,
    East = 5,
    Northwest = 6,
    Northeast = 7,
    North = 8,
    Unknown = 9,
}
