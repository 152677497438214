export const avalancheAspectLabels = new Map<string, string>([
    ['1', 'Southwest'],
    ['2', 'Southeast'],
    ['3', 'South'],
    ['4', 'West'],
    ['5', 'East'],
    ['6', 'Northwest'],
    ['7', 'Northeast'],
    ['8', 'North'],
    ['9', 'Unknown'],
]);
