export enum AvalancheCause {
    Snowmobiler = 1,
    Explosive = 2,
    Natural = 3,
    Skier = 4,
    Snowboarder = 5,
    Hiker = 6,
    Snowshoer = 7,
    'Snow Bike' = 8,
    Unknown = 9,
}
